<template>
	<div class="flex-column-center-center" style="width: 100%;height: 100%;">
		<vue-seamless-scroll class="warp" :data="images" :class-option="classOption">
			<ul class="ul-item">
				<li class="li-item" v-for="(item, index) in images" :key="index" @mouseenter="handlerMouseEnter(index)"
					@mouseleave="handleMouseLeave(index)">
					<div style="display: flex; align-items: center; justify-content: center; height: 100%;">
						<!-- <label v-show="item.show"> {{ item.title }} </label> -->
						<!-- <img v-show="!item.show" :src="item.pic" class="pic" :title="item.title" /> -->
						<img v-show="!item.show" :src="item.pic" class="pic" />
						<img v-show="item.show" :src="item.picSelected" class="pic" />
					</div>
				</li>
			</ul>
		</vue-seamless-scroll>
	</div>
</template>

<script>
	export default {
		name: "",
		props: {
			title: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				images: [{
						title: '这是标题1',
						pic: '/imgs/bar_1.png',
						picSelected: '/imgs/bar_1_selected.png',
						show: false
					},
					{
						title: '这是标题2',
						pic: '/imgs/bar_2.png',
						picSelected: '/imgs/bar_2_selected.png',
						show: false
					},
					{
						title: '这是标题3',
						pic: '/imgs/bar_3.png',
						picSelected: '/imgs/bar_3_selected.png',
						show: false
					},
					{
						title: '这是标题4',
						pic: '/imgs/bar_4.png',
						picSelected: '/imgs/bar_4_selected.png',
						show: false
					},
					{
						title: '这是标题5',
						pic: '/imgs/bar_5.png',
						picSelected: '/imgs/bar_5_selected.png',
						show: false
					},
					{
						title: '这是标题6',
						pic: '/imgs/bar_6.png',
						picSelected: '/imgs/bar_6_selected.png',
						show: false
					},
					{
						title: '这是标题7',
						pic: '/imgs/bar_7.png',
						picSelected: '/imgs/bar_7_selected.png',
						show: false
					},
					{
						title: '这是标题8',
						pic: '/imgs/bar_8.png',
						picSelected: '/imgs/bar_8_selected.png',
						show: false
					},
					{
						title: '这是标题8',
						pic: '/imgs/bar_9.png',
						picSelected: '/imgs/bar_9_selected.png',
						show: false
					},
					{
						title: '这是标题8',
						pic: '/imgs/bar_10.png',
						picSelected: '/imgs/bar_10_selected.png',
						show: false
					},
					{
						title: '这是标题1',
						pic: '/imgs/bar_11.png',
						picSelected: '/imgs/bar_11_selected.png',
						show: false
					},
					{
						title: '这是标题2',
						pic: '/imgs/bar_12.png',
						picSelected: '/imgs/bar_12_selected.png',
						show: false
					},
					{
						title: '这是标题3',
						pic: '/imgs/bar_13.png',
						picSelected: '/imgs/bar_13_selected.png',
						show: false
					},
					{
						title: '这是标题4',
						pic: '/imgs/bar_14.png',
						picSelected: '/imgs/bar_14_selected.png',
						show: false
					},
					{
						title: '这是标题5',
						pic: '/imgs/bar_15.png',
						picSelected: '/imgs/bar_15_selected.png',
						show: false
					},
				],
				classOption: {
					step: 0.8, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				},
			}
		},
		created() {

		},
		methods: {
			handlerMouseEnter(index) {
				console.log('***handlerMouseEnter');
				this.images[index].show = true;
			},

			handleMouseLeave(index) {
				console.log('***handleMouseLeave');
				this.images[index].show = false;
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	::v-deep .seamless-warp > div > :nth-child(2) {
		display: none !important;
	}

	::v-deep .warp > div > :nth-child(2){
		display: none !important;
	}

	.warp {
			width: 100vw;
			/* height: 240px; */
			overflow: hidden;

			>div{
				width: 99999px !important;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: auto;
				&.ul-item {
					display: flex;
					.li-item {
						/* width: 260px; */
						/* height: 160px; */
						padding-right: 30px;
						margin: auto;
						background-color: transparent;
						color: #000000;
						text-align: center;
						font-size: 30px;
						.pic {
							width: 23vw;
							height: auto;
							padding: 30px 0;
						}
	
						img:hover {
							/* transform: scale(1.3); */
						}
					}
				}
			}
	}
</style>
<template>
	<div id="app" @mousemove="handleMouseMove">
		<div class="nav-container" :class="{ 'header--hidden': showNavbar }" :style="`background-image: `+bgcolor+`;`">
			<div class="flex-row-spacebetween-center" style="width: 100%;height: 100%;">
				<div v-if="pageWidth >= 1005" class="flex-row-spacebetween-center" style="flex: 1;height: 100%;">
					<img src="./assets/icon.png" style="width: 150px;height: auto;margin-top: 5px;margin-left: 5px;" />
					<div class="flex-row-end-center" style="flex: 1;height: 100%;">
						<div class="flex-row-center-center" @click="index=0" @mouseenter="mouseIndex=0" @mouseleave="mouseIndex=null" style="margin-right: 10px;">
							<a href="/" v-if="index==0||mouseIndex==0"
								class="t7BoldFont whiteColor flex-row-center-center"
								style="width: 100px;border: 1px solid #ffffff;padding: 4px 0;border-radius: 4px;">首页</a>
							<a href="/" v-else class="t5BoldFont whiteColor flex-row-center-center"
								style="width: 100px;">首页</a>
						</div>
						<div class="flex-row-center-center" @click="index=1" @mouseenter="mouseIndex=1" @mouseleave="mouseIndex=null" style="margin-right: 10px;">
							<a href="/store" v-if="index==1||mouseIndex==1"
								class="t7BoldFont whiteColor flex-row-center-center"
								style="width: 100px;border: 1px solid #ffffff;padding: 4px 0;border-radius: 4px;">关于渔民湾</a>
							<a href="/store" v-else class="t5BoldFont whiteColor flex-row-center-center"
								style="width: 100px;">关于渔民湾</a>
						</div>
						<div class="flex-row-center-center" @click="index=2" @mouseenter="mouseIndex=2" @mouseleave="mouseIndex=null" style="margin-right: 10px;">
							<a href="/dishes" v-if="index==2||mouseIndex==2"
								class="t7BoldFont whiteColor flex-row-center-center"
								style="width: 100px;border: 1px solid #ffffff;padding: 4px 0;border-radius: 4px;">渔家美食</a>
							<a href="/dishes" v-else class="t5BoldFont whiteColor flex-row-center-center"
								style="width: 100px;">渔家美食</a>
						</div>
						<!-- <div class="flex-row-center-center" @click="index=3" @mouseenter="mouseIndex=3"
							@mouseleave="mouseIndex=null" style="margin-right: 10px;">
							<a href="/shop" v-if="index==3||mouseIndex==3"
								class="t7BoldFont whiteColor flex-row-center-center"
								style="width: 100px;border: 1px solid #ffffff;padding: 4px 0;border-radius: 4px;">门店信息</a>
							<a href="/shop" v-else class="t5BoldFont whiteColor flex-row-center-center"
								style="width: 100px;">门店信息</a>
						</div> -->
						<div class="flex-row-center-center" @click="index=4" @mouseenter="mouseIndex=4"
							@mouseleave="mouseIndex=null" style="margin-right: 10px;">
							<a href="/connectUs" v-if="index==4||mouseIndex==4"
								class="t7BoldFont whiteColor flex-row-center-center"
								style="width: 100px;border: 1px solid #ffffff;padding: 4px 0;border-radius: 4px;">联系我们</a>
							<a href="/connectUs" v-else class="t5BoldFont whiteColor flex-row-center-center"
								style="width: 100px;">联系我们</a>
						</div>
						<div class="flex-row-center-center" style="height: 100%;">
							<span class="whiteColor t0BoldFont">中文</span>
							<img src="./assets/home_hongqi.png" style="width: 20px;height: auto;margin: 0 5px;" />
							<span class="whiteColor t0BoldFont">中国内地</span>
						</div>
					</div>
				</div>
				<div v-else class="flex-row-start-center" style="flex: 1;height: 100%;">
					<img src="./assets/icon.png" style="width: 150px;height: auto;margin-top: 5px;margin-left: 5px;" />
					<div class="flex-row-end-center" style="flex: 1;height: 100%;">
						<div class="flex-row-center-center" style="height: 100%;">
							<span class="whiteColor t0BoldFont">中文</span>
							<img src="./assets/home_hongqi.png" style="width: 20px;height: auto;margin: 0 5px;" />
							<span class="whiteColor t0BoldFont">中国内地</span>
						</div>
						<div class="flex-row-end-center" style="width: 40px;height: 40px;"
							@click="clickMenu=!clickMenu">
							<img v-if="clickMenu" src="../src/assets/home_cha.png" style="width: 24px;height: auto;" />
							<img v-else src="../src/assets/home_caidan.png" style="width: 24px;height: auto;" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="clickMenu" class="whiteBgColor flex-column-start-center"
			style="height: 100vh;width: 100%;z-index: 99999;top: 60px;position: fixed;left: 0;">
			<div class="flex-row-start-center"
				style="width: 100%;border-bottom: 1px solid #eee;padding: 10px 20px;box-sizing: border-box;">
				<a class="t4Font" href="/" style="width: 100%;height: 100%;color: #b68f51;">首页</a>
			</div>
			<div class="flex-row-start-center"
				style="width: 100%;border-bottom: 1px solid #eee;padding: 10px 20px;box-sizing: border-box;">
				<a class="t4Font blackColor" href="/store" style="width: 100%;height: 100%;">关于渔民湾</a>
			</div>
			<div class="flex-row-start-center"
				style="width: 100%;border-bottom: 1px solid #eee;padding: 10px 20px;box-sizing: border-box;">
				<a class="t4Font blackColor" href="/dishes" style="width: 100%;height: 100%;">渔家美食</a>
			</div>
			<!-- <div class="flex-row-start-center"
				style="width: 100%;border-bottom: 1px solid #eee;padding: 10px 20px;box-sizing: border-box;">
				<a class="t4Font blackColor" href="/shop" style="width: 100%;height: 100%;">门店信息</a>
			</div> -->
			<div class="flex-row-start-center"
				style="width: 100%;border-bottom: 1px solid #eee;padding: 10px 20px;box-sizing: border-box;">
				<a class="t4Font blackColor" href="/connectUs" style="width: 100%;height: 100%;">联系我们</a>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'app',
		components: {

		},
		data() {
			return {
				oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
				scrollTop: 0, // 记录当前的滚动距离
				bgcolor: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))!important;',
				tran_flag: true,
				showNavbar: false,
				fade: '',
				index: 0,
				mouseIndex: null,
				mousechiIndex: null,
				pageWidth: window.innerWidth,
				clickMenu: false,
				clickMenuIndex: 0
			}
		},
		created() {
			console.log('进入主页', window.location.href);
			this.clickMenu = false
			this.clickMenuIndex = 0
			if (window.location.href.includes('store')) {
				this.index = 1
			} else if (window.location.href.includes('dishes')) {
				this.index = 2
			} else if (window.location.href.includes('shop')) {
				this.index = 3
			} else if (window.location.href.includes('connectUs')) {
				this.index = 4
			} else {
				this.index = 0
			}
		},
		watch: {
			scrollTop(newValue, oldValue) {
				setTimeout(() => {
					if (newValue == window.scrollY) { //延时执行后当newValue等于window.scrollY，代表滚动结束
						console.log('滚动结束');
						this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
						this.tran_flag = true;
					};
				}, 300); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
				if (this.oldScrollTop == oldValue) { //每次滚动开始时oldScrollTop与oldValue相等
					console.log('滚动开始');
					this.tran_flag = false;
				}
			}
		},

		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			window.addEventListener('resize', this.handleResize);
			document.title = '渔民湾';
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll);
			window.removeEventListener('resize', this.handleResize);
		},
		methods: {
			handleResize() {
				this.pageWidth = window.innerWidth;
				if (this.pageWidth < 1005) {
					this.clickMenu = false
					this.clickMenuIndex = 0
				}
			},
			dropdownChange1(e) {
				// this.$message('click on item ' + e);
				if (!e && this.mouseIndex == 1) {
					this.mouseIndex = null
				}
			},
			dropdownChange2(e) {
				// this.$message('click on item ' + e);
				if (!e && this.mouseIndex == 2) {
					this.mouseIndex = null
				}
			},
			handleScroll() {
				const currentScrollTop = document.documentElement.scrollTop
				this.scrollTop = currentScrollTop
				// if (currentScrollTop > this.oldScrollTop) {
				// 	this.showNavbar = true
				// } else {
				// 	this.showNavbar = false
				// }
				if (currentScrollTop > 0) {
					this.showNavbar = true
					this.mouseIndex = null
				} else {
					this.showNavbar = false
				}
			},
			handleMouseMove(event) {
				// 检查鼠标是否在顶部区域（比如顶部60像素内）
				if (event.clientY <= 60) {
					this.showNavbar = false
				} else {
					if (this.scrollTop > 0) {
						this.showNavbar = true
					}
				}
			},
			pushHome() {},
			pushStore() {},
			pushUs() {},
		}
	}
</script>

<style>
	@import "../common/css/common.css";

	#app {
		font-family: "Avenir", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	* {
		margin: 0;
		padding: 0;
		border: 0;
	}

	html,
	body,
	* {
		cursor: url('./assets/shubiao.png'), default;
	}

	.nav-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		padding: 0 10px;
		box-sizing: border-box;
		transition: opacity 0.5s ease-in-out;
		z-index: 9999;
	}

	.header--hidden {
		opacity: 0;
		pointer-events: none;
	}

	.no-border {
		border: none;
	}

	.el-menu {
		transition: all 0.3s;
	}

	.el-menu.el-menu--horizontal {
		border: none;
	}

	::-webkit-scrollbar {
		width: 0 !important;
	}

	::-webkit-scrollbar {
		width: 0 !important;
		height: 0;
	}

	/* 覆盖全局下拉菜单背景色 */
	.el-dropdown-menu {
		background-color: transparent !important;
		background-image: url('./assets/menuBg.png') !important;
		/* 引用本地图片 */
		background-size: cover !important;
		/* 背景图片覆盖整个容器 */
		background-position: center !important;
		/* 背景图片居中 */
		background-repeat: no-repeat !important;
		/* 背景图片不重复 */
		z-index: 10000 !important;
		margin-top: 10px !important;
		border-radius: 15px !important;
		width: 150px !important;
		padding: 3px !important;
	}

	.el-dropdown-menu__item {
		text-align: center !important;
		border-bottom: 1px solid #eee;
		color: white !important;
		cursor: url('./assets/shubiao.png'), default !important;
		/* 将鼠标指针设置为默认箭头 */
	}

	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: transparent !important;
		color: #b68f51 !important;
	}

	/* 消除小三角 */
	.el-popper[x-placement^=bottom] .popper__arrow {
		border: none;
	}

	.el-popper[x-placement^=bottom] .popper__arrow::after {
		border: none;
	}

	.expand-enter-active,
	.expand-leave-active {
		transition: all 0.5s ease;
	}
</style>